import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Scroll, Link } from 'react-scroll';
import '../assets/css/footer.less';

const Footer = () => (
  <Container fluid className="footer">
    <footer>
      <div className="footer-container">
        <Row className="footer-row">
          <Col xs="4" sm="4" className="footer-col">
            <span className="footer-col-container">
              <h1 className="footer-container__heading">
                Blossom Beauty Salon
              </h1>
              <p className="footer-container__description">
                9705 James Potter Rd Brampton, ON, Canada
              </p>
              <p className="footer-container__description">(905) 453-1331</p>
            </span>
          </Col>
          <Col xs="4" sm="4" className="footer-col">
            <span className="footer-col-container">
              <h3 className="footer-container__title">Follow</h3>
              <ul className="footer-container__list">
                <li className="footer-container__list-item">
                  <a
                    href="https://www.instagram.com/blossombsalon/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li className="footer-container__list-item">
                  <a
                    href="https://www.facebook.com/blossombsalon/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
              </ul>
            </span>
          </Col>
          <Col xs="4" sm="4" className="footer-col">
            <span className="footer-col-container">
              <h3 className="footer-container__title">Explore</h3>
              <ul className="footer-container__list">
                <li className="footer-container__list-item">
                  <Link to="about" smooth={true} duration={500}>About</Link>
                </li>
                <li className="footer-container__list-item">
                  <Link to="services" smooth={true} duration={500}>Services</Link>
                </li>
                <li className="footer-container__list-item">
                <Link to="contact" smooth={true} duration={500}>Make An Appointment</Link>
                </li>
              </ul>
            </span>
          </Col>
        </Row>
      </div>
    </footer>
  </Container>
);

export default Footer;
