import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../assets/css/services.less';

const Services = () => (
  <div className="services">
    <section className="services-section">
      <h1 className="services-section--title">Our Services</h1>
      <p className="services-section--subtitle">
        Treat yourself to one of our signature services. Our beloved beauty
        experts will wow your brows, get you glowing and so much more. You’ll
        leave looking and feeling better than ever before.
      </p>
    </section>

    <div className="services-collection">
      <Row className="services-row">
        <Col xs="12" md="6" lg="4" className="services-col">
          <img
            className="services-col--image"
            src="../images/hairstyling.png"
            alt=""
          />
          <h2 className="services-col--title">Hairstyling</h2>
          <p className="services-col--description">
            Improve your look with our professional haircut & hairstyling
            services
          </p>
        </Col>
        <Col xs="12" md="6" lg="4" className="services-col">
          <img
            className="services-col--image"
            src="../images/makeup.png"
            alt=""
          />
          <h2 className="services-col--title">Makeup</h2>
          <p className="services-col--description">
            Our makeup experts will have you looking your best everytime
          </p>
        </Col>
        <Col xs="12" md="6" lg="4" className="services-col">
          <img
            className="services-col--image"
            src="../images/haircutting.png"
            alt=""
          />
          <h2 className="services-col--title">Men's Haircutting</h2>
          <p className="services-col--description">
            Have a seat in one of our barber chairs, we'll take care of you
          </p>
        </Col>
        <Col xs="12" md="6" lg="4" className="services-col">
          <img
            className="services-col--image"
            src="../images/laser.png"
            alt=""
          />
          <h2 className="services-col--title">Laser Hair Removal</h2>
          <p className="services-col--description">
            Exceptional laser hair removal guaranteed to give results
          </p>
        </Col>
        <Col xs="12" md="6" lg="4" className="services-col">
          <img
            className="services-col--image"
            src="../images/skincare.png"
            alt=""
          />
          <h2 className="services-col--title">Skin Care</h2>
          <p className="services-col--description">
            We offer a full range of relaxing skin care services, including all
            types of facials.
          </p>
        </Col>
        <Col xs="12" md="6" lg="4" className="services-col">
          <img
            className="services-col--image"
            src="../images/manicure-pedicure.png"
            alt=""
          />
          <h2 className="services-col--title">Manicure & Pedicure</h2>
          <p className="services-col--description">
            Pamper yourself with our manicure & pedicure services that will
            bring sensational results.
          </p>
        </Col>
      </Row>
    </div>
  </div>
);

export default Services;
