/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Header from './Header';
import Home from './Home';
import '../assets/css/main.less';

export default class Main extends Component {
  render() {
    return (
      <div className="main">
        <Header />
        <Home />
      </div>
    );
  }
}
