import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../assets/css/about.less';

const About = () => (
  <div className="about">
    <Row className="about-row">
      <Col xs="12" md="12" lg="6" className="about-col-collage" />
      <Col xs="12" md="12" lg="6" className="about-col-text">
        <section className="about-section">
          <h1 className="about-section--title">
            Why Our Clients{' '}
            <span className="about-section--title--secondary">Choose Us</span>
          </h1>
          <p className="about-section--subtitle">
            We are the leading beauty salon in Brampton, ON providing
            high-quality hairdressing, makeup, and skin care services to
            everyone.
          </p>
          <p className="about-section--description">
            Our clients appreciate and value our level of customer service, the
            skills of our stylists and estheticians as well as our professional
            approach to everything we do.
          </p>
        </section>
      </Col>
    </Row>
  </div>
);

export default About;
