import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import '../assets/css/contact.less';

class Contact extends React.Component {
  constructor() {
    super();

    this.state = {
      isFormSubmit: false,
      formSubmitStatus: null,
      buttonText: "MAKE AN APPOINTMENT NOW"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    this.setState({ buttonText: "MESSAGE SENT" })
    
    const form = {};
    formData.forEach((value, key) => {
      form[key] = value;
    });

    fetch('/v1/submit', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(form),
    })
      .then((response) => response.status)
      .then((status) => {
        this.setState({
          isFormSubmit: true,
          formSubmitStatus: status,
        });

      this.submitForm.reset();
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  render() {
    const { isFormSubmit, formSubmitStatus, buttonText } = this.state;
    return (
      <div className="contact">
        <section className="contact-section">
          <h1 className="contact-section--title">Make an Appointment</h1>
          <p className="contact-section--subtitle">
            The best way to enjoy a treatment at our salon is to book an
            appointment. Fill in the form below and we will contact you to
            discuss your appointment.
          </p>
        </section>

        <div className="contact-form">
          <Form
            ref={(form) => {
              this.submitForm = form;
            }}
            onSubmit={this.handleSubmit}
          >
            <Form.Row className="contact-row">
              <Col xs="12" md="6" lg="6" className="contact-col">
                <Form.Group controlId="formBasicName">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    className="contact-col--control"
                    name="name"
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6" lg="6" className="contact-col">
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    className="contact-col--control"
                    name="email"
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6" lg="6" className="contact-col">
                <Form.Group controlId="formBasicPhone">
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    className="contact-col--control"
                    name="phoneNumber"
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs="12" md="6" lg="6" className="contact-col">
                <Form.Group controlId="formbasicService">
                  <Form.Control
                    required
                    as="select"
                    defaultValue="Select a Service..."
                    className="contact-col--control"
                    name="service"
                  >
                    <option disabled>
                      Select a Service...
                    </option>
                    <option>Hair Colouring</option>
                    <option>Keratin Hair Smoothing</option>
                    <option>Laser Hair Removal</option>
                    <option>Skincare Facial</option>
                    <option>Threading</option>
                    <option>Waxing</option>
                    <option>Manicure & Pedicure</option>
                    <option>Haircut</option>
                    <option>Other</option>
                    <option></option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs="12" md="12" lg="12" className="contact-col">
                <Form.Group controlId="formBasicComments">
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Additional Comments"
                    className="contact-col--control comments"
                    name="comments"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Button type="submit" className={"contact-button " + (buttonText === "MESSAGE SENT" ? 'clicked' : '')}>{buttonText}</Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default Contact;
