/* eslint-disable react/no-deprecated */
/* eslint-disable react/sort-comp */
import React from 'react';
import Fade from 'react-reveal/Fade';
import { Button } from 'react-bootstrap';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Footer from './Footer';
import { Scroll, Link } from 'react-scroll';
import '../assets/css/home.less';

class Home extends React.Component {
  render() {
    return (
      <div className="content">
        <div className="banner">
          <section className="banner-section">
            <h1 className="banner-section--title">
              <span className="banner-section--title__experience">
                Experience{' '}
              </span>
              the Best
              <span className="banner-section--title__services">
                Beauty Services
              </span>
            </h1>
            <Fade>
              <p className="banner-section--description">
                We offer a full range of hairdressing services for men and
                women, eyebrow and eyelash care, the services of make-up artists
                and stylists. Entrust your beauty to professionals who really
                care about your style and look!
              </p>
            </Fade>
            <Link to="contact" smooth={true} duration={500}>
            <Button className="banner-section--button">MAKE AN APPOINTMENT NOW</Button></Link>
          </section>
          <Fade>
            <img
              className="banner-image"
              src="../images/banner-image.png"
              alt=""
            />
          </Fade>
        </div>
        <About />
        <Services />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default Home;
